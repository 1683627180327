import { configureStore, combineReducers } from '@reduxjs/toolkit';
import accountReducer from '../reducers/accountSlice';
import readyReducer from '../reducers/readySlice';

const rootReducer = combineReducers({
    account: accountReducer,
    ready: readyReducer,
})

export const store = configureStore({
    reducer: rootReducer
})
