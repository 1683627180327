import { createSlice } from '@reduxjs/toolkit'

export const accountSlice = createSlice({
    name: 'account',
    initialState: {
        id: 0,
        name: '',
        skipTourHome: false,
    },
    reducers: {
        setUserAccount: (state, action) => {
            state.id = action.payload.id;
            state.name = action.payload.name;
            state.skipTourHome = action.payload.skipTourHome;
        },
        setSkipTourHome: (state, action) => {
            state.skipTourHome = action.payload;
        },
        clearUserAccount: state => {
            state.id = 0;
            state.name = "";
            state.skipTourHome = false;
        }
    }
})

export const { setUserAccount, clearUserAccount, setSkipTourHome } = accountSlice.actions

export const selectAccount = state => state.account

export default accountSlice.reducer